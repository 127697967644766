.about_container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: -30px;
  margin: 15px 0 0 0;
}

.about {
  text-align: left;
  width: 60vw;
  font-family: 'Raleway', sans-serif;
  font-size: 1vw;
  font-weight: 300;
  color: #4A4A4A;
  line-height: 2.5;
  border-left: 1px solid;
}

.about > * {
  margin: 0 0 0 16px;
}

.about_title {
  font-size: 2vw;
  font-weight: 600;
}

.skills_title {
  font-size: 2vw;
  font-weight: 600;
  margin: 0;
  border-left: 1px solid;
  padding: 8px 0 0 12px;
}

.skills_title_container {
  display: flex;
  margin: 0 0 20px 50px;
}

.skills_wrapper {
  margin: 0 40px;
}

.skills_container {
  background-color: white;
  width: auto;
  height: calc(20/1vw);
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  border-radius: 30px;
  padding: 0px;
}

.skills_wrapper {
  position: relative;
  top: -12px;
}
.skills_combo {
  border: 1px solid #cecece;
  box-shadow: 0px 0px 18px #a2a2a2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95px;
  width: 95px;
  margin: 25px;
}

.skills_combo > * {
  color: #4A4A4A;
  padding: 4px;
}

.skills_image {
  width: fit-content;
}

ul {
  list-style-type: none;
}

.skills_photo {
  width: 30vw;
  height: 19vw;
}

.arrow {
  width: 60px;
  height: 40px;
  position: relative;
  top: -24px;
}

.arrow:hover {
  cursor: pointer;
  transform: translateY(5px);
}

@media screen and (max-width: 1023px){
  .about_container{
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  
  .about {
    font-size: 3.5vw;
    width: 100%;
    margin: 35px 0 0 0;
  }

  .skills_photo {
    width: 90%;
    height: 100%;
  }

  .about_title, .skills_title {
    font-size: 4.5vw;
    text-decoration: underline;
  }

  .skills_title_container {
    display: flex;
    margin: 0;
  }
}
