@media screen and (max-width: 1023px){
  .home_image {
    height: 100%;
    width: 100%;
    animation-duration: 2s;
    animation-name: fade-in-left;
  }
  
  .home_heading {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 10vw;
    animation-duration: 2s;
    animation-name: fade-in-right;
    color: #4A4A4A;
    width: auto;
  }
  
  .home_paragraph {
    font-family: 'Raleway', sans-serif;
    animation-duration: 2s;
    animation-name: fade-in-right;
    font-weight: 200;
    text-align: center;
    color: #4A4A4A;
  }
  
  .home_2_wrapper > * {
    margin: 36px 10px;
  }
  
  .home_buttons {
    animation-duration: 2s;
    animation-name: fade-in-right;
    display: flex;
    flex-flow: wrap row;
    justify-content: center;
  }
  
  .home_buttons > * {
    border: 1px solid #cecece;
    padding: 16px 20px 8px;
    box-shadow: 0px 0px 18px #a2a2a2;
    border-radius: 12px;
    margin: 10px;
  }
  
  .home_resume {
    background-color: #ddc5c2;
    padding: 30px;
  }

  .home_resume > * {
    font-family: 'Raleway', sans-serif;
    color: #4A4A4A;
    padding: 20px;
    font-weight: 500;
    text-decoration: none;
  }

  .home_buttons > a:hover, .home_resume:hover {
    box-shadow: 0px 0px 20px #747474;
    transform: translateY(-5px);
  }
  
  .home_resume:hover > * {
    color: rgb(53, 108, 171);
  }

  span {
    color: #b1655b;
    font-weight: 700;
  }
  
  .arrow {
    width: 60px;
    height: 40px;
    position: relative;
  }
  
  .arrow:hover {
    cursor: pointer;
    transform: translateY(5px);
  }
  
  @keyframes fade-in-left {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
  
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fade-in-right {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
  
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@media screen and (min-device-width: 1024px){
  
  .home_container {
    display: flex;
    flex-flow: wrap row;
    align-items: center;
  }
  
  .home_image {
    width: 50vw;
    height: 32vw;
    animation-duration: 2s;
    animation-name: fade-in-left;
  }
  
  @keyframes fade-in-left {
    0% {
      transform: translateX(-200px);
      opacity: 0;
    }
  
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes fade-in-right {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
  
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .home_2_wrapper {
    position: relative;
    top: 100px;
    width: 50vw;
  }
  
  .home_heading {
    position: relative;
    right: 120px;
    bottom: 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 6vw;
    animation-duration: 2s;
    animation-name: fade-in-right;
    color: #4A4A4A;
    width: auto;
  }
  
  .home_paragraph {
    font-family: 'Raleway', sans-serif;
    border-left: 1px solid;
    text-align: left;
    animation-duration: 2s;
    animation-name: fade-in-right;
    position: relative;
    left: 25px;
    bottom: 50px;
  }
  
  .home_paragraph > * {
    padding: 2px 0 2px 16px;
    font-size: 1vw;
    font-weight: 300;
    color: #4A4A4A;
    width:fit-content;
  }
  
  .home_buttons {
    display: inline-flex;
    animation-duration: 2s;
    animation-name: fade-in-right;
    position: relative;
    bottom: 40px;
  }
  
  .home_buttons > * {
    border: 1px solid #cecece;
    padding: 12px 16px 8px;
    box-shadow: 0px 0px 18px #a2a2a2;
    border-radius: 12px;
    margin: 20px;
  }
  
  .home_resume {
    background-color: #ddc5c2;
    width: auto;
  }
  
  .home_resume > * {
    font-family: 'Raleway', sans-serif;
    color: #4A4A4A;
    padding: 20px;
    font-weight: 400;
    text-decoration: none;
    font-size: 1vw;
  }
  
  .home_buttons > a:hover, .home_resume:hover {
    box-shadow: 0px 0px 20px #747474;
    transform: translateY(-5px);
  }
  
  .home_resume:hover > * {
    color: rgb(53, 108, 171);
  }
  
  span {
    color: #b1655b;
    font-weight: 700;
  }
  
  .arrow {
    width: 60px;
    height: 40px;
    position: relative;
    bottom: -60px;
  }
  
  .arrow:hover {
    cursor: pointer;
    transform: translateY(5px);
  }
}

@media screen and (prefers-reduced-motion: reduce) {  
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
