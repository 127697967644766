.works1_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: nowrap row;
  font-family: 'Raleway', sans-serif;
  font-size: 1.5vw;
  font-weight: 200;
  text-align: left;
  margin: 0 0 120px 0;
}

.works1_image {
  width: 50vw;
  height: 100%
}

.works1_title {
  font-size: 4vw;
  font-weight: 500 ;
}

.works1_counter_container {
  margin: 50px 0 0 0;
  display: flex;
  font-size: 3vw;
}

.works1_counter::before {
  content: "";
  width: 210px;
  height: 1px;
  background-color: black;
  display: inline-block;
  margin: 0 10px 0 0;
}

.works1_counter {
  font-weight: 300;
}

.works1_description {
  max-width: 40vw;
}

.works1_description > a {
  color: #000000;
  text-decoration: none;
}

.works1_description > a:hover {
  color: #BB7E8C;
}

.works2_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: nowrap row;
  font-family: 'Raleway', sans-serif;
  font-size: 1.5vw;
  font-weight: 200;
  text-align: right;
  margin: 0 0 120px 0;
}

.works2_counter_container {
  margin: 50px 0 0 0;
  display: block;
  font-size: 3vw;
}

.works2_counter::after {
  content: "";
  width: 210px;
  height: 1px;
  background-color: black;
  display: inline-block;
  margin: 0 0 0 10px;
}

.works2_counter {
  font-weight: 300;
}

@media screen and (max-width: 767px){
  
  .works1_description > p, a {
    font-size: 3vw;
  }

  .works2_counter::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: black;
    display: inline-block;
    margin: 0;
  }

  .works2_counter::after {
    height: 0px;
  }

  .works2_counter {
    width: 100%;
  }

  .works1_container, .works2_container {
    margin: 0 0 60px 0;
  }
}