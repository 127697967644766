.navbar {
  background-color: #f7f7f7;
  display: flex;
  flex-flow: wrap row;
  justify-content: flex-end;
  padding: 20px 0 0 0;
  width: 100%;
  position: fixed;
  top: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 100;
  margin: 0;
  z-index: 1;
}

.links_container {
  display: flex;
  flex-flow: wrap row;
  margin: 0 20px 0 0;
}

.navbar_item {
  cursor: pointer;
  padding: 0 40px;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
}

.navbar_item > * {
  margin: 0 10px;
  font-size: 20px;
}

img {
  width: 35px;
  height: 35px;
}

.navbar_link:hover {
  color: #BB7E8C;
  transform: translateY(5px);
}

@media screen and (max-width: 538px){
  .navbar_item {
    padding: 2px;
  }
  .navbar{
    justify-content: center;
  }
}