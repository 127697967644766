.footer_container {
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  background-color: #ddc5c2;
  font-size: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 500;
}